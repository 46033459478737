import { useLocation } from 'react-router-dom';

export default () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const query = {};
  for (const [key, value] of searchParams.entries()) {
    query[key] = value;
  }

  return query;
};
