import React, { useState, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { useJsApiLoader } from '@react-google-maps/api';
import { Paragraph, Heading, Flex, Box } from 'theme-ui';
import { sort } from 'fast-sort';

import client from 'lib/client';
import { getGeocode, formatResult } from 'lib/geocoding';

import Autocomplete from 'components/Map/Autocomplete';
import { CentreTile } from 'components/Map/Centre';
import Map from 'components/Map';
import Loader from 'components/common/Loader';

import { ReactComponent as LocationIcon } from 'icons/location.svg';

import { LIBRARIES } from 'constants/google';

import { CentreSearchQuery } from 'queries/centres';
import useEmergencyAppointmentMode from 'hooks/useEmergencyAppointmentMode';
import useQueryParams from 'hooks/useQueryParams';

const now = DateTime.local();
const oneWeek = now.plus({ weeks: 1 });

const MapSearch = ({ setCentre, searchResults, setSearchResults }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: LIBRARIES,
  });
  const searchParams = useQueryParams();
  const [results, setResults] = useState(searchResults?.results);
  const [searchQuery, setSearchQuery] = useState(searchResults?.term || '');
  const emergencyAppointmentMode = useEmergencyAppointmentMode();
  const { data, loading } = useQuery(CentreSearchQuery, {
    client: client,
    skip: !results,
    variables: {
      input: {
        coordinates: {
          latitude: results?.latitude,
          longitude: results?.longitude,
        },
        state: results?.state,
        radius: Number(searchParams.searchRadius) || 20000,
      },
      nextAppointment: {
        start: now.toISODate(),
        end: oneWeek.toISODate(),
      },
    },
  });

  useEffect(() => {
    setSearchResults({ term: searchQuery, results });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const centres = useMemo(() => {
    const sorted = emergencyAppointmentMode
      ? sort(data?.centreSearch?.centres || []).by([
          {
            asc: centre => {
              return centre.nextAppointment
                ? new Date(centre.nextAppointment.date).valueOf()
                : Number.MAX_SAFE_INTEGER;
            },
          },
          { asc: centre => centre.distance },
          { desc: centre => centre.nextAppointment?.number },
        ])
      : data?.centreSearch?.centres;

    return sorted?.map(centre => ({
      ...centre,
      availableAppointmentsToday:
        !!centre.nextAppointment &&
        DateTime.fromISO(centre.nextAppointment?.date).hasSame(
          DateTime.now(),
          'day'
        ),
      availableAppointmentsTomorrow:
        !!centre.nextAppointment &&
        DateTime.fromISO(centre.nextAppointment?.date).hasSame(
          DateTime.now().plus({
            days: 1,
          }),
          'day'
        ),
    }));
  }, [data, emergencyAppointmentMode]);

  const resultListCentres = useMemo(() => {
    const resultList = emergencyAppointmentMode
      ? centres?.filter(centre => {
          return !!centre?.hours?.find(hour => {
            return (
              hour.isOpen &&
              {
                monday: 1,
                tuesday: 2,
                wednesday: 3,
                thursday: 4,
                friday: 5,
                saturday: 6,
                sunday: 0,
              }[hour.day] === new Date().getDay()
            );
          });
        })
      : centres;

    return (resultList?.length ? resultList : centres)?.slice(0, 5);
  }, [centres, emergencyAppointmentMode]);

  const onPositionChange = async ({ coords }) => {
    const results = await getGeocode({
      location: { lat: coords.latitude, lng: coords.longitude },
    });
    const result = results.find(r => r.types.includes('locality'));
    const details = await formatResult(result);
    setResults(details);
  };

  const onPositionError = error => {
    console.error(error);
  };

  const getGeoPosition = () => {
    navigator.geolocation.getCurrentPosition(
      onPositionChange,
      onPositionError,
      {
        enableHighAccuracy: true,
        timeout: 10 * 1000,
        maximumAge: 60000,
      }
    );
  };

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <Flex
      sx={{
        backgroundColor: 'background',
        flexDirection: 'column',
        '@media (min-width: 576px)': {
          position: 'absolute',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          height: '100%',
        },
      }}
    >
      <Box
        sx={{
          padding: '16px',
          '@media (min-width: 576px)': {
            paddingX: '28px',
            paddingY: '32px',
            width: '410px',
            height: '100%',
            overflowY: 'auto',
          },
        }}
      >
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Heading
            as="h2"
            variant="styles.mapTitle"
            color="primary"
            fontFamily="heading"
          >
            {emergencyAppointmentMode ? 'Find an appointment' : 'Find a centre'}
          </Heading>
          <Flex
            onClick={() => getGeoPosition()}
            sx={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Paragraph
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontSize: 0,
                textAlign: 'right',
                marginRight: '8px',
              }}
            >
              use my location
            </Paragraph>
            <LocationIcon width="16px" />
          </Flex>
        </Flex>

        <Autocomplete
          setResults={setResults}
          defaultValue={searchResults?.term}
          onSelect={query => query && setSearchQuery(query)}
        />

        {!results && (
          <Paragraph
            sx={{
              marginTop: '28px',
            }}
          >
            Please enter your suburb to find your nearest dental centre.
          </Paragraph>
        )}

        {results && !loading && !!centres?.length && (
          <Paragraph
            sx={{
              fontSize: 0,
              marginTop: '28px',
            }}
          >
            {emergencyAppointmentMode ? (
              <>
                If no appointments are displayed but a centre near you is open
                please call as additional emergency appointments are reserved
                daily.
              </>
            ) : (
              <>
                Displaying 5 closest centres in <strong>{results.state}</strong>
                . Refer to map for all available locations.
              </>
            )}
          </Paragraph>
        )}

        {results && !loading && !centres?.length && (
          <Paragraph
            sx={{
              fontSize: 0,
              marginTop: '28px',
            }}
          >
            No centres located near you.
          </Paragraph>
        )}

        {results && loading && (
          <Paragraph
            sx={{
              fontSize: 0,
              marginTop: '28px',
            }}
          >
            Searching for centres in <strong>{results.state}</strong>...
          </Paragraph>
        )}

        {results &&
          !loading &&
          resultListCentres?.map(centre => (
            <CentreTile
              emergencyAppointmentMode={emergencyAppointmentMode}
              key={centre.id}
              centre={centre}
              setCentre={setCentre}
            />
          ))}
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '300px',
          paddingX: '16px',
          paddingBottom: '16px',
          display:
            results && !loading && data?.centreSearch?.centres.length
              ? 'block'
              : 'none',
          '@media (min-width: 576px)': {
            display: 'block',
            padding: 0,
            width: 'calc(100vw - 410px)',
            height: '100%',
          },
        }}
      >
        <Map
          results={results}
          data={data}
          loading={loading}
          setCentre={setCentre}
        />
      </Box>
    </Flex>
  );
};

export default MapSearch;
