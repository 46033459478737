import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Flex, Box } from 'theme-ui';
import slugify from 'slugify';

const Radio = ({ name, label, choices, ...props }) => {
  const { values } = useFormikContext();

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        flexWrap: 'nowrap',
      }}
    >
      {label && (
        <Box as="legend" sx={{ marginBottom: '24px' }}>
          {label}
        </Box>
      )}
      <Flex
        sx={{
          flexDirection: 'row',
          flexWrap: 'nowrap',
        }}
      >
        {choices?.map(choice => (
          <Box
            as="label"
            sx={{
              color: 'text',
              backgroundColor: 'rgb(241, 241, 241)',
              fontSize: [1, 2],
              fontWeight: 'bold',
              padding: ['0.5rem 1rem 0.5rem 3rem', '1rem 2rem 1rem 4rem'],
              cursor: 'pointer',
              position: 'relative',
              marginRight: '1rem',
              '&[data-active="true"]': {
                borderColor: 'primary',
                color: 'primary',
                boxShadow: 'rgb(20, 74, 56) 0px 0px 0px 2px inset',
                '&:after': {
                  backgroundColor: 'rgb(20, 74, 56)',
                },
              },
              ':before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                backgroundColor: 'rgb(255, 255, 255)',
                top: '50%',
                transform: 'translateY(-50%)',
                width: ['1.5rem', '2rem'],
                height: ['1.5rem', '2rem'],
                left: ['0.75rem', '1rem'],
                borderRadius: '50%',
                boxShadow: 'rgb(20, 74, 56) 0px 0px 0px 2px inset',
              },
              '&:after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                backgroundColor: 'rgb(255, 255, 255)',
                top: '50%',
                transform: 'translateY(-50%)',
                width: ['0.75rem', '1rem'],
                height: ['0.75rem', '1rem'],
                left: ['1.125rem', '1.5rem'],
                borderRadius: '50%',
              },
              input: {
                appearance: 'none',
                backgroundColor: 'transparent',
                color: 'rgb(20, 74, 56)',
                position: 'absolute',
                top: '0',
                left: '0',
                margin: '0',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              },
            }}
            key={choice?.value}
            htmlFor={name}
            data-active={values?.[name] === choice?.value}
          >
            <Field
              id={slugify(`${name}-${choice?.value}`)}
              name={name}
              type="radio"
              value={choice?.value}
              {...props}
            />
            {choice?.label}
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default Radio;
