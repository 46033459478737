import { Box, Flex, Heading } from 'theme-ui';

export const FieldWrapper = ({
  children,
  title,
  innerRef,
  borderColor = 'white',
  alignItems = 'stretch',
  textAlign = 'left',
  flexProps = {},
}) => {
  return (
    <Box
      ref={innerRef}
      sx={{
        backgroundColor: 'white',
        borderRadius: '4px',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor,
        padding: ['12px', '32px', '32px'],
        width: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        alignItems,
        textAlign,
      }}
    >
      {title && <FieldHeading>{title}</FieldHeading>}
      <Flex
        sx={{
          flexDirection: 'column',
          flexWrap: 'nowrap',
          gridGap: '16px',
          alignItems,
          textAlign,
          ...flexProps,
        }}
      >
        {children}
      </Flex>
    </Box>
  );
};

export const FieldHeading = ({ children }) => {
  return (
    <Heading
      as="h3"
      variant="styles.h3"
      sx={{
        marginBottom: '24px',
      }}
    >
      {children}
    </Heading>
  );
};
