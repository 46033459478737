export function trackEvent(eventData) {
  if (window && window?.dataLayer) {
    window.dataLayer.push({
      ...eventData,
    });
  }
}

export function trackInputEvent(input, location) {
  trackEvent({
    event: 'form',
    formStatus: 'formEdited',
    formName: 'bookingWidget',
    formElement: input,
    centerName: `NIB ${location}`,
  });
}

export function hasExistingEvent(key, value) {
  if (window && window?.dataLayer) {
    const event = window.dataLayer.find(event => event?.[key] === value);
    return !!event;
  }
  return true;
}
