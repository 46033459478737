import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { CentrePopup } from 'components/Map/Centre';

import Pin from 'images/pin.png';

const Map = ({ loading, data, results, setCentre }) => {
  const mapRef = useRef(null);
  const mapBoundsRef = useRef({
    lat: -25.2744,
    lng: 133.7751,
  });

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = centre => {
    if (centre.id === activeMarker) {
      return;
    }

    setActiveMarker(centre.id);

    mapRef.current.panTo({
      lat: centre.latitude,
      lng: centre.longitude,
    });
  };

  useEffect(() => {
    if (!mapRef?.current || loading) {
      return;
    }

    const mapLocations = [];

    data?.centreSearch?.centres?.forEach(centre => {
      mapLocations.push({
        latitude: centre.latitude,
        longitude: centre.longitude,
      });
    });

    if (!mapLocations.length) {
      mapLocations.push({
        latitude: results.latitude,
        longitude: results.longitude,
      });
    }

    const bounds = new window.google.maps.LatLngBounds();

    mapLocations.forEach(loc => {
      bounds.extend(new window.google.maps.LatLng(loc.latitude, loc.longitude));
    });

    // Uses the current map position if new search returns no centres
    mapBoundsRef.current = bounds.getCenter();
    mapRef.current.fitBounds(bounds);
    mapRef.current.panToBounds(bounds);

    if (mapLocations.length === 1) {
      mapRef.current.setZoom(16);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, loading, data]);

  const onLoad = map => {
    mapRef.current = map;
  };

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '100%',
      }}
      center={mapBoundsRef?.current}
      zoom={5}
      onLoad={onLoad}
    >
      {data?.centreSearch?.centres?.map(centre => (
        <Marker
          key={centre.id}
          position={{
            lat: centre.latitude,
            lng: centre.longitude,
          }}
          icon={Pin}
          onClick={() => handleActiveMarker(centre)}
        >
          {activeMarker === centre.id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div
                style={{
                  padding: '16px 12px',
                  width: '350px',
                }}
              >
                <CentrePopup centre={centre} setCentre={setCentre} />
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap>
  );
};

export default React.memo(Map);
